.Title {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 600;
  color: #262837;
}

.LoginInsteadText {
  margin-top: 20px;
  font-size: 13px;
  color: #525968;
}

.LoginInsteadText > a {
  color: #5abced;
}
