.CurrentPassText {
  margin-top: 8px;
}

.NewPassText {
  margin-top: 26px;
}

.Alert {
  margin-top: 8px;
}