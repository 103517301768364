div[data-cc-component="MaintenanceBar"] {
  height: 36px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(to bottom, #db5a6b, #9d2933);

  .MuiTypography-root {
    margin: auto;
    text-transform: uppercase;
    font-weight: 600;
    color: #ffffff;
  }
}
