.Box[data-variant="normal"] {
  height: 90px;

  .Right {
    .TimestampLabel {
      font-size: 13px;
    }

    .Timestamp {
      font-size: 13px;
    }

    .ActionText {
      font-size: 14px;
    }

    .ActionTextDisabled {
      font-size: 14px;
    }

    .Pulse {
      margin-top: 4px;
      margin-bottom: -4px;
    }
  }
}

.Box[data-variant="simple"] {
  height: 75px;

  .Right {
    .TimestampLabel {
      font-size: 12px;
    }

    .Timestamp {
      font-size: 11px;
    }

    .ActionText {
      font-size: 13px;
    }

    .ActionTextDisabled {
      font-size: 13px;
    }

    .Pulse {
      margin-top: 2px;
      margin-bottom: -2px;
    }
  }
}

.Box {
  position: relative;
  width: 100%;
  margin-top: 5px;
  border-radius: 4px;
  border: solid 1px #d8dde4;
  box-sizing: border-box;

  .Left {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 16px);
    height: calc(100% - 20px);
    padding: 10px 0 10px 10px;
    justify-content: space-between;

    .TitleRow {
      min-height: 0;
      display: flex;
      align-items: center;

      .Icon {
        height: 18px;
        width: 18px;
        color: #1498d8;
      }

      .Text {
        margin-left: 4px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1;
        color: #262837;
      }
    }

    .StatusRow {
      display: flex;
      align-items: center;

      &[data-comm-status="VERIFIED"] {
        color: #00a044;
      }

      &:not([data-comm-status="VERIFIED"]) {
        color: #ad1505;
      }

      .Text {
        margin-left: 4px;
        font-size: 13px;
        font-weight: 600;
      }

      .Icon {
        height: 18px;
        width: 18px;
      }
    }

    .Value {
      font-size: 13px;
      color: #262837;
    }

    .NotAddedText {
      font-size: 13px;
      font-style: italic;
      color: #7b8291;
    }
  }

  .Right {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 16px);
    height: calc(100% - 32px);
    padding: 16px 16px 16px 0;
    justify-content: center;
    text-align: right;

    .TimestampLabel {
      color: #bac3ce;
    }

    .Timestamp {
      margin-bottom: 4px;
      color: #262837;
    }

    .GoToEdit {
      font-size: 13px;
      font-style: italic;
      color: #7b8291;

      .Bolder {
        font-weight: 500;
      }
    }

    .ActionText {
      color: #1498d8;
    }

    .ActionTextDisabled {
      color: #7b8291;
    }
  }

  .PulseDot {
    width: 14px !important;
    height: 14px !important;
    margin-left: 0 !important;
    margin-right: 2px;
    background-color: #1498d8 !important;
  }
}
