.Button {
  margin-top: 30px !important;
}

.ErrorText {
  height: 18px;
  margin: 8px 0 0 0;
  font-size: 12px;
  color: #e74c3c;
}

.LogoutInsteadText {
  margin-top: 20px;
  font-size: 13px;
  color: #525968;
}

.LogoutInsteadText > a {
  color: #5abced;
}
