.PulseStage {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.Pulse {
  margin: auto;
  padding-bottom: 17px;
}

.PulseDot {
  background-color: #1498d8 !important;
}

.RefreshText {
  font-size: 13px;
  font-weight: 600;
  color: #5abced;
}