.MuiButton-root[data-cc-component="UserMenu"] {
  z-index: 1;
  display: flex;
  position: absolute;
  right: 0;
  margin: 0 16px;
  padding: 3px 4px 3px 8px;
  align-items: center;
  border-radius: 4px;
  border: solid 1px transparent;

  &[data-active="true"],
  &:active,
  &:hover:active {
    background-color: #7bbbe2;
    background-blend-mode: overlay;
    border: solid 1px transparent;
  }

  &:hover {
    background-color: unset;
    border: solid 1px rgba(255, 255, 255, 0.35);
  }

  .MuiButton-label {
    font-size: 14px;
    font-weight: normal;
    color: #ffffff;
  }
}

.MuiMenu-list[data-cc-component="UserMenu"] {
  .MuiListItem-root {
    padding: 16px 8px;

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      color: #bac3ce;
    }

    .MuiTypography-root {
      flex-grow: 1;
      font-size: 14px;
      color: #525968;
    }
  }
}
