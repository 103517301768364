.MenuOpenIcon {
  width: 24px;
  height: 24px;
  padding: 20px 18px;
  color: #1498d8;
}

.MenuCloseIcon {
  width: 28px;
  height: 28px;
  padding: 20px 18px;
  color: #ffffff;
}

.Branding {
  width: calc(100vw - 36px);
  margin: 42px 17px;

  .CloudCathLogo {
    width: 56px;
    height: 56px;
  }

  .CloudCathText {
    height: 33px;
    margin-top: 8px;
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
  }
}

.WaveStage {
  position: relative;
  overflow: visible;

  .Wave {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    opacity: 0.45;
    mix-blend-mode: overlay;
    background-image: url("../../../assets/branding/wave.svg");
    background-size: cover;
  }
}
