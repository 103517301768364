.UserNameRow {
  display: flex;
  padding: 8px 0;
  align-items: baseline;
}

.UserNameText {
  font-size: 16px;
  font-weight: 600;
  color: #262837;
}

.RefreshIconButton {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: #1498d8;
  transform: scale(-1, 1);
  align-self: center;
  padding: 0;
}

@keyframes RefreshIconSpin {
  from {
    transform: rotate(0deg) scale(-1, 1);
  }
  to {
    transform: rotate(360deg) scale(-1, 1);
  }
}

.RefreshIconSpin {
  animation-name: RefreshIconSpin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.VerifyCommsBox {
  min-width: 540px;
}

.ErrorText {
  height: 18px;
  margin: 8px 0 0 0;
  font-size: 12px;
  color: #e74c3c;
}