.Title {
  display: flex;
  margin-bottom: 20px !important;
}

.RefreshIconButton {
  width: 24px;
  height: 24px;
  margin-left: 16px;
  margin-right: 8px;
  color: #1498d8;
  transform: scale(-1, 1);
  padding: 0;
}

@keyframes RefreshIconSpin {
  from {
    transform: rotate(0deg) scale(-1, 1);
  }
  to {
    transform: rotate(360deg) scale(-1, 1);
  }
}

.RefreshIconSpin {
  animation-name: RefreshIconSpin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ErrorText {
  height: 18px;
  margin: 8px 0 0 0;
  font-size: 12px;
  color: #e74c3c;
}

.LogoutInsteadText {
  margin-top: 4px;
  font-size: 13px;
  color: #525968;
}

.LogoutInsteadText > a {
  color: #5abced;
}
