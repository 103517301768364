$wh: var(--cloudcath-wh, 1vh);

.Desktop,
.MobilePanels {
  display: flex;
  background-color: #edeff3;
}

.Desktop {
  min-width: 812px;
  min-height: calc((#{$wh} * 100) - 48px);
  padding: 24px;

  .Panels {
    display: flex;
    min-width: 812px;
    max-width: 812px;
    height: 540px;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    .WavePanel {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      width: 352px;
      padding-top: 149px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-image: linear-gradient(to bottom, #5abced, #1498d8);

      .Branding {
        flex-grow: 1;
        margin: 0 auto 0;
        text-align: center;

        .CloudCathText {
          margin: 11px 0 0;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .MainPanel {
      width: calc(460px - 79px - 79px);
      padding: 79px;
    }
  }
}

.MobilePanels {
  $mobile-min-width: 320px;
  $mobile-width: 100vw;

  min-width: $mobile-min-width;
  width: $mobile-width;
  min-height: 100vh; // calc(#{$wh} * 100); -- CBF-239, did not use full height on Safari/Chrome

  .Panels {
    display: flex;
    flex-direction: column;
    min-width: inherit;
    width: inherit;
    min-height: inherit;

    .Branding {
      display: flex;
      position: absolute;
      top: 0;
      min-width: inherit;
      width: inherit;
      min-height: calc(max(135px, #{$wh} * 20));
      text-align: center;

      .CloudCathText {
        margin-top: 7px;
        margin-bottom: -4px;
        color: #ffffff;
      }
    }

    .WavePanel {
      position: absolute;
      top: 0;
      display: flex;
      flex-direction: column-reverse;
      min-width: inherit;
      width: inherit;
      height: calc(max(260px, #{$wh} * 45));
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      background-image: linear-gradient(to bottom, #5abced, #1498d8);
    }

    .MainPanel {
      $margin-top: calc(max(135px, #{$wh} * 20));
      z-index: 1;
      min-width: calc(#{$mobile-min-width} - 24px - 48px);
      width: calc(#{$mobile-width} - 24px - 48px);
      min-height: calc((#{$wh} * 100) - #{$margin-top} - 48px - 24px - 12px);
      margin: $margin-top 12px 12px;
      padding: 48px 24px 24px;
      border-radius: 20px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
    }
  }
}

.WavePanel .Wave {
  width: 100%;
  height: 200px;
  opacity: 0.45;
  mix-blend-mode: overlay;
  background-image: url("../../../assets/branding/wave.svg");
  background-size: cover;
}

.Branding {
  .CloudCathLogo {
    width: 78px;
    height: 78px;
  }

  .CloudCathText {
    height: 33px;
    font-size: 24px;
    font-weight: 600;
  }
}

.MobileFullscreen {
  padding: 40px 36px;

  .Branding {
    margin-bottom: 62px;

    .CloudCathText {
      margin: 8px 0 0;
      color: #1498d8;
    }
  }
}
