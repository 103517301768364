.MuiPagination-root[data-cc-component="CCPagination"] {

  .MuiPaginationItem-icon {
    color: #1498d8;
  }

  .MuiPaginationItem-root {
    font-weight: 500;
    color: #a0abb9;

    &.Mui-selected {
      color: #1498d8;
      background-color: unset;
    }
  }

  .MuiPaginationItem-page:hover {
    background-color: unset;
  }
}