.ProfileIcon {
  width: 24px;
  height: 24px;
  color: #ffffff;
  margin-left: 12px;
  margin-right: 4px;
}

.CaretIcon {
  width: 16px;
  height: 16px;
  color: #ffffff;
}

.VersionSection {
  display: block;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}