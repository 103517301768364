.MuiDialog-root[data-cc-component="MobileNavBar"] {
  .MuiDialog-paper {
    background-image: linear-gradient(to bottom, #5abced, #1498d8);

    .MuiList-root {
      flex-grow: 1;
      margin: 25px 0;
      padding: 0;

      .MuiListItem-divider {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: solid 1px #6dbced;
      }

      .MuiListItem-root {
        padding-left: 18px;
        padding-right: 18px;

        .MuiListItemIcon-root {
          min-width: unset;
          width: 24px;
          height: 24px;
          color: #ffffff;
          margin-right: 8px;
          align-items: center;
        }

        .MuiListItemText-root {
          font-size: 18px;
          color: #ffffff;

          &[data-bolder="true"] .MuiTypography-root {
            font-weight: 600;
          }
        }
      }
    }
  }
}
