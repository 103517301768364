@page {
  size: auto;
  margin: 25px;
}

.PrintButton {
  @media print {
    display: none;
  }
}

.ReportSection {
  width: calc(8.5in - 50px);
  min-height: 11in;
  padding: 25px;
  box-shadow: -0.05px 0px 0px 0px #000, 0.05px 0px 0px 0px #000;

  //background: repeating-linear-gradient(
  //                45deg,
  //                rgba(204, 204, 204, 0.1),
  //                rgba(204, 204, 204, 0.1) 10px,
  //                rgba(238, 238, 238, 0.1) 10px,
  //                rgba(238, 238, 238, 0.1) 20px
  //);

  @media print {
    width: 100%;
    padding: 1px;
    box-shadow: unset;
  }
}