.MuiButton-root[data-cc-component="CCButton"] {
  &[data-margin="none"] {
    margin: 0;
  }

  &[data-animate="true"] {
    .MuiButton-label {
      position: relative;
      display: flex;
      align-self: center;
    }
  }

  @media screen and (max-width: 400px) {
    min-width: auto;
    margin-left: 0;
    margin-right: 6px;
  }

}
