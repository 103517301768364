.MuiFormControl-root[data-cc-component="CCSelect"] {
  .MuiInputBase-root {
    font-size: 14px;
    color: #262837;
  }

  .MuiFormHelperText-root {
    height: 18px;
    margin: 8px 0 0 0;
    font-size: 12px;

    .Mui-error {
      color: #e74c3c;
    }
  }
}
