.Title {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #262837;
}

.Text {
  font-size: 14px;
  margin-left: 5px;
}

.HelperText {
  height: 18px;
  margin: 8px 0 0 0;
  font-size: 12px;
  color: #000000;
  opacity: 0.54;
}