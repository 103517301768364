.MuiAppBar-root[data-cc-component="NavBar"] {
  background-image: linear-gradient(to bottom, #5abced, #1498d8);

  .MuiToolbar-root {
    min-height: 36px;
  }

  .MuiTabs-root {
    width: 100%;
    min-height: unset;

    .MuiTabs-indicator {
      height: 4px;
      background-color: #ffffff;
    }

    .MuiTab-root {
      width: 72px;
      min-width: unset;
      max-width: unset;
      min-height: 36px;
      margin: 0 12px 0 12px;
      padding: unset;
      opacity: unset;
      font-size: 14px;
      font-weight: unset;
      line-height: unset;
      text-align: center;
      color: #ffffff;

      &:hover {
        box-shadow: inset 0 -4px 0 0 #8dcff0;
      }

      &.Mui-selected {
        font-weight: 600;
      }

      .MuiTab-wrapper {
        width: unset;
      }
    }
  }
}
