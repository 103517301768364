.InstructionText {
  font-size: 16px;
}

.InstructionText .Bolder {
  font-weight: 600;
}

.RedoText {
  margin-top: 8px;
  font-size: 13px;
  color: #5abced;
}

.RedoDisabledText {
  margin-top: 8px;
  font-size: 13px;
  color: #bac3ce;
}

.MuiInputLabel {
  margin-top: 20px;
}

.MuiInputBase-input {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 10px;
}

.MuiInputBase-input::placeholder {
  font-weight: normal;
  text-transform: none;
  letter-spacing: normal;
}

.VerificationNoticeText {
  margin-top: 8px;
  font-size: 12px;
  color: #bac3ce;
}

.RememberThisDeviceText {
  font-size: 13px;
  color: #525968;
}

.Button {
  margin-top: 30px !important;
}

.ButtonWithErrorShown {
  margin-top: 4px !important;
}


.LogoutInsteadText {
  margin-top: 20px;
  font-size: 13px;
  color: #525968;
}

.LogoutInsteadText > a {
  color: #5abced;
}
