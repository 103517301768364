.ToolbarSpacer {
  min-height: 36px;
}

.CloudCathLogo {
  position: absolute;
  width: 58.5px;
  height: 36px;
  opacity: 0.2;
}

.CloudCathText {
  position: absolute;
  margin-left: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
