div[data-cc-component="TurbidityGraphSvg"] {

  .x-tick .tick text {
    font-family: Poppins;
    font-size: 10px;
    fill: #262837;
  }

  .x-tick .tick line {
    display: none;
  }

  .y-tick .tick text {
    font-family: Poppins;
    font-size: 10px;
    fill: #7b8291;
  }

  .y-tick .tick line {
    display: none;
  }

  .y-grid .tick line {
    color: #edeff3;
  }

  .line-solid {
    fill: none;
    stroke: url(#gradient-solid);
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .line-solid-notification-zone {
    fill: none;
    stroke: #e74c3c;
    stroke-width: 2px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }

  .line-dotted {
    fill: none;
    stroke: url(#gradient-dotted);
    stroke-width: 2px;
    stroke-dasharray: (1, 1);
  }

  .line-dotted-notification-zone {
    fill: none;
    stroke: #f2988f;
    stroke-width: 2px;
    stroke-dasharray: (1, 1);
  }

  .pointer-capture {
    fill: none;
    pointer-events: all;
  }

  .focus-marker {
    stroke: #ffffff;
    stroke-width: 1px;
    transform: rotate(45deg);
  }

  .focus-line {
    fill: none;
    stroke: #bac3ce;
    stroke-width: 0.5px;
    stroke-dasharray: (3, 3);
  }

  .focus-text-box {
    height: 20px;
    fill: #ffffff;
  }

  .focus-text-value {
    font-family: Poppins;
    font-size: 12px;
    transform: translate(5px, 15px)
  }

  .focus-text-time {
    font-family: Poppins;
    font-size: 12px;
    text-anchor: end;
    fill: #bac3ce;
  }

  .today-marker {
    fill: #bac3ce;
  }

  .today-line {
    fill: none;
    stroke: #bac3ce;
    stroke-width: 2px;
    stroke-dasharray: (4, 4);
    animation: today-line 1s linear infinite;
  }

  @keyframes today-line {
    to {
      stroke-dashoffset: 16;
    }
  }

  .notification-level-line {
    fill: none;
    stroke: #e74c3c;
    stroke-width: 2px;
    stroke-dasharray: (0.25, 4);
    stroke-linecap: round;
  }

  .notification-level-text {
    font-family: Poppins;
    font-size: 10px;
    fill: #e74c3c;
  }

  .escalation-highlight {
    fill: #fce4e2;
    opacity: 0.38;
  }
}
